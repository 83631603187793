import React from 'react'
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { IconButton, Typography, Stack } from '@mui/material';
import Upload from '@mui/icons-material/Upload';
import { red } from '@mui/material/colors';
import axios from 'axios';
import { postRecord } from '../actions/postRecord';
import { ModalCropper } from './ModalCropper';
import { resizeImage } from '../actions/resizeImage';
import StyledButton from '../styled/StyledButton';
import { Replay } from '@mui/icons-material';
import { motion } from 'framer-motion';

type UploadFilePropsType = {
    accept?: string;
    title?: string;
    dataUri: any;
    imageSrc?: any; 
    onChange: any;
    handleDelete: any;
    setCurrentPalette?: any;
    handleModal?: any;
    openModal?: boolean;
    file?: any;
    getCropData?: any;
    setCropper?: any;
    cropper?: any;
    temporalDataUri?: string | null;
    useCropper?: boolean;
    roundedPreview?: boolean;
    maxFileSize?: number;
    aspectRatio?: number | number[];
    height?: number;
}

export const dataURItoBlob = (dataURI:string) => {
    if( !dataURI ) {
        return;
    }
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});

}


export const fileToDataUri = (file:any) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        if(event.target) {
            resolve(event.target.result)
        }
    };
    reader.readAsDataURL(file);
});

export const useUploader = ( useCropper = false,  initialState = null ) => {
    const [ dataUri, setDataUri ] = React.useState<null | string>(initialState);
    const [ imageServerUid, setImageServerUid ] = React.useState<null | string>( null );
    const [ imageHasChanged, setImageHasChanged ] = React.useState<boolean>( false );
    const [ temporalDataUri, setTemporalDataUri ] = React.useState<null | string>( initialState );
    const [ imageSrc, setImageSrc ] = React.useState<null | string>(initialState);
    const [ file, setFile ] = React.useState<any | string>(initialState);
    const [ openModal, setOpenModal ] = React.useState<boolean>( false );

    const [cropper, setCropper] = React.useState<any>();
    const handleModal = () => {
        setOpenModal( !openModal );
    }

    const getCropData = () => {
        
        if (typeof cropper !== "undefined") {
            const croppedImageUri = cropper.getCroppedCanvas().toDataURL();
            setDataUri( croppedImageUri );
            handleModal();
        }
    };

    const uploadToCloudinary = async () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'tm2ljrm7')

        try{
            //const result = await cloudinary.uploader.upload( file );
            const {data} = await axios.post(
                'https://api.cloudinary.com/v1_1/nucleodev/image/upload',
                formData
            );
            
            setImageSrc( data.secure_url );
            return data.secure_url;
        }catch(e) {
            console.log(e)
        }
        
    }

    const onChange = (file:any) => {
        
        if(!file) {
          setDataUri('');
          return;
        }
    
        fileToDataUri(file)
          .then((dataUri:any) => {
            useCropper ? setTemporalDataUri( dataUri ) : setDataUri( dataUri )
          });
        
          if( useCropper ) {
            handleModal();
          }

          setFile(file); 
    }

    const uploadToServer = async ( cloudinaryFolder?:string ) => {
        const formData = new FormData();
        if( !file ) {
            return;
        }

        if( cloudinaryFolder ) {
            formData.append( 'folderName', cloudinaryFolder );
        }

        let fileToUpload:any = null;

        if( useCropper ) {
            let dataBlob:Blob = new Blob();
            let metadata = {
                type: file.type
              };

              if( dataUri ) {
                dataBlob = dataURItoBlob( dataUri )
              }

              fileToUpload = new File([ dataBlob ], file.name, metadata);
              cropper.destroy();
        }else {
            fileToUpload = file;
        }

        const resized:any = await resizeImage( fileToUpload ); //Starts resizing image
         
        formData.append( 'file', resized );
        const dataImage = await fileToDataUri( resized );

        return {
            dataImage, 
            type: file.type,
            name: file.name
        }
    }

    const handleDelete = async () => {
        setImageHasChanged( true );
        setDataUri(null);
        setImageSrc(null);
    }

    return { temporalDataUri, dataUri, imageSrc, handleDelete, onChange, uploadToCloudinary, file, uploadToServer, openModal, handleModal , getCropData, setCropper, cropper, setDataUri, imageServerUid, setImageServerUid, imageHasChanged, setImageHasChanged  }
};


export const UploadFile = ({ 
    accept = '.jpg, .jpeg, .png',
    title,
    dataUri,
    onChange,
    handleDelete,
    openModal,
    handleModal,
    setCropper,
    cropper,
    getCropData,
    temporalDataUri,
    maxFileSize = 5242880,
    roundedPreview = false,
    useCropper = false,
    aspectRatio = 1,
    height
}:UploadFilePropsType) => {

    const theme = useTheme();
    const fileInput = React.useRef<HTMLInputElement>(null);


    return (
        <>
            { useCropper && 
                <ModalCropper
                    cropper={cropper}
                    openModal={ openModal }
                    handleModal={ handleModal }
                    file={ temporalDataUri }
                    setCropper={ setCropper }
                    getCropData={ getCropData }
                    aspectRatio={ aspectRatio }
                />
            }
            <Box
                sx={{   
                    position: 'relative',
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 8,
                    borderStyle: 'dashed',
                    minHeight: height ? height : 'auto',
                    width: 'auto',
                    p: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                {
                    !dataUri && (
                        <Typography 
                            color="primary"
                            align="center"
                            variant="caption"
                        >
                            { title ? title : 'Sube tu logo o foto'}
                        </Typography>
                    )
                }
      
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 2,
                    }}
                >
                    <input
                        accept={ accept }
                        ref={ fileInput }
                        id="input-file"
                        type="file"
                        onChange={(event) => {
                            if(event.target.files) {
                                
                                if(event.target.files[0].size > maxFileSize ) {
                                    alert(`El archivo no debe ser mayor a ${ Math.round(maxFileSize/1000000) }MB`);
                                    return;
                                }
                                onChange(event?.target.files[0] || null)
                            }
                        }}
                        hidden
                    />
                    {
                        dataUri ?
                        <>
                            <Stack
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                <img
                                    style={{
                                        maxWidth: 250,
                                        borderRadius: roundedPreview ? '50%' : 16
                                    }}
                                    src={ dataUri }
                                    alt="preview"
                                />
                                <StyledButton
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={ handleDelete }
                                    startIcon={ <Replay/> }
                                >
                                    Probar con otra imagen
                                </StyledButton>
                            </Stack>
                 
                        </>
                        
                        : (
                            <motion.div 
                            animate={{ scale: [1, 1.1, 1] }}
                            transition={{ ease: "linear", duration: 1.5, repeat: Infinity }}
                            >
                                <IconButton
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white'
                                    }}
                                    onClick={ () => {
                                        if(fileInput.current) {
                                            fileInput.current.click();
                                        }
                                    } }
                                >   
                                    <Upload sx={{ fontSize: 60 }}/>
                                </IconButton>
                            </motion.div>
                        )
                        
                    }
                </Box>    
            </Box>       
        </>
    )
}

